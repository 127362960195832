<template>
  <div class="banner" :style="{backgroundColor: banner.backgroundColour}">
    <div class="grid-x align-middle">
      <div class="cell small-7 medium-offset-1 medium-6">
        <p class="title" v-html="banner.title"/>
        <p class="subtitle" v-html="banner.text"/>
      </div>
      <div class="cell small-5 medium-4">
        <v-image class="image" :image="banner.bottles"/>
      </div>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  export default {
    props: {
      banner: Object
    },
    components: {
      VImage
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .banner {
    display: flex;
    align-items: flex-end;
    padding: vw-relative(70 20 0, 320);
    @include breakpoint(medium) {
      padding: vw-relative(130 0 0);
    }
  }
  .image {
    width: 100%;
    height: auto;
  }
  .title {
    font: 300 #{vw-relative(20, 320)}/1.1 Brown;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    margin-bottom: .2em;
    @include breakpoint(medium) {
      font-size: vw-relative(60);
    }
  }
  .subtitle {
    font: 300 #{vw-relative(14, 320)}/1.2 Brown;
    @include breakpoint(medium) {
      font-size: vw-relative(17);
    }
  }
</style>
