<template>
  <div v-if="page">
    <banner :banner="page.banner"/>
    <div class="grid-x stockists">
      <div class="cell medium-10 medium-offset-1">
        <stockist-list v-for="stockistList in page.stockistLists" :key="stockistList.title" :list="stockistList"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Banner from '@/components/where-to-buy/Banner.vue';
  import StockistList from '@/components/where-to-buy/StockistList.vue';

  export default {
    metaInfo: {
      title: 'Black Tower',
      titleTemplate: null
    },
    components: {
      Banner,
      StockistList
    },
    mounted() {
      this.fetchPage('where-to-buy')
        .then(() => {
        });
    },
    methods: {
      ...mapActions(['fetchPage']),
    },
    computed: {
      page() {
        return this.$store.getters.getPage('where-to-buy');
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .stockists {
    @include breakpoint(small only) {
      padding: 0 vw-relative(20, 320);
    }
  }
</style>
