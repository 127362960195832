<template>
  <div class="list">
    <p class="title">{{ list.title }}</p>
    <div class="stockists">
      <a class="stockist" v-for="(stockist, index) in list.stockists" :key="index" :href="stockist.link ? stockist.link : null" target="_blank">
        <div class="background" :style="{backgroundColor: list.iconBackgroundColour}">
          <v-image class="icon" :image="stockist.icon" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import VImage from '@/components/Image.vue';
  export default {
    props: {
      list: Object
    },
    components: {
      VImage
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .list {
    margin: vw-relative(36, 320) 0;
    @include breakpoint(medium) {
      margin: vw-relative(36) 0;
    }
    &:first-child {
      margin-top: vw-relative(60, 320) 0;
      @include breakpoint(medium) {
        margin-top: vw-relative(60);
      }
    }
  }
  .title {
    font: #{vw-relative(14, 320)}/1.1 Brown;
    text-transform: uppercase;
    margin-bottom: .8em;
    @include breakpoint(medium) {
      font-size: vw-relative(22);
    }
    @include breakpoint(xlarge) {
      font-size: 22px;
    }
  }
  $gutter: ('small': 10, 'medium': 24);
  .stockists {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -#{vw-relative(calc(map-get($gutter, small) / 2), 320)};
    @include breakpoint(medium) {
      margin: 0 -#{vw-relative(calc(map-get($gutter, medium) / 2))};
    }
  }
  .stockist {
    width: calc(100%/3);
    margin-bottom: vw-relative(map-get($gutter, small), 320);
    padding: 0 vw-relative(calc(map-get($gutter, small) / 2), 320);
    @include breakpoint(medium) {
      width: calc(100%/5);
      margin-bottom: vw-relative(map-get($gutter, medium));
      padding: 0 vw-relative(calc(map-get($gutter, medium) / 2));
    }
  }
  .background {
    padding-top: 100%;
    position: relative;
  }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
    padding: 0 vw-relative(10, 320);
    @include breakpoint(medium) {
      padding: 0 vw-relative(30);
    }
  }
</style>
